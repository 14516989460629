import posthog from 'posthog-js'
import type { BaseSyntheticEvent } from 'react'

type Enumerate<
  N extends number,
  Acc extends number[] = [],
> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>

type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>

type ElementId = string
type EventDescription = string
type InteractionDescription = string

export type INTERACTION_TYPE = 'button' | 'dropdown' | 'link'

export type EVENT =
  | 'success'
  | 'warning'
  | 'failure'
  | 'input_start'
  | 'input_submit'
  | 'toggle'
  | 'checkbox'
  | 'click_interaction'
  | 'visualization'
  | 'scroll_depth'
  | 'login'

type EventParams =
  | {
      name: 'visualization'
      label?: string
      personId?: string
    }
  | {
      name: 'success' | 'warning' | 'failure' | 'input_start'
      interaction_result?: string
    }
  | {
      name: 'input_start'
      source_id?: ElementId
      label?: string
    }
  | {
      name: 'input_submit'
      source_id?: ElementId
      label?: string
      interaction_result: string
      interaction_label?: InteractionDescription
    }
  | {
      name: 'toggle' | 'checkbox'
      source_id?: ElementId
      label?: string
      interaction_label?: InteractionDescription
      interation_result: number | ElementId
    }
  | {
      name: 'click_interaction'
      source_id?: ElementId
      label?: string
      interaction_type?: INTERACTION_TYPE
      interaction_result?: number | string
    }
  | {
      name: 'visualization'
      label?: string
      source_id?: ElementId
      interaction_type?: INTERACTION_TYPE
      interaction_data?: Object
    }
  | {
      name: 'scroll_depth'
      depth_pc: IntRange<0, 100>
      depth_px: number
    }
  | {
      name: 'login'
      user_id: string
    }
  | {
      name: 'click_interaction'
      label: string
      personId: string
      placeId: string
      source: 'pagamento' | 'onboarding'
      rating: string
      details: string
      singleBill?: boolean
    }
  | {
      name: 'click_interaction'
      label: string
      personId: string
      status: string
    }
  | {
      name: 'visualization'
      label: string
      status: string
      singleBill?: boolean
    }

type TrackEventParams = {
  event?: BaseSyntheticEvent
  label?: EventDescription
  source_id?: ElementId
} & EventParams

export function gtag(params: TrackEventParams) {
  if (!window?.gtag) {
    return
  }

  const { name, event, ...bodyParams } = params
  const { name: targetName, id } = event?.target ?? {}

  let sourceId = targetName ?? id

  if (params.source_id) {
    sourceId = params.source_id
  }

  window.gtag('event', name, {
    ...bodyParams,
    source_id: sourceId,
  })
}

export enum EVENT_NAME {
  LOGIN_TAP_HELPER = 'login_tap_helper',
  LOGIN_CPF_ERROR = 'login_cpf_error',
  LOGIN_TAP_NEXT = 'login_tap_next',
  LOGIN_TAP_NEW_CLIENT = 'login_tap_new_client',
  LOGIN_TAP_NO_ACCESS_CONTACTS = 'login_tap_no_access_contacts',
}

export async function posthogEvent(params: TrackEventParams) {
  const { name, event, ...bodyParams } = params

  posthog.capture(name, bodyParams)
}

export function trackEvent(params: TrackEventParams) {
  gtag(params)
  posthogEvent(params)
}

type EventsV2Name =
  | 'click'
  | 'visualization'
  | 'interaction'
  | 'success'
  | 'fail'

type EventsV2 = {
  name: EventsV2Name
  label?: string
  result?: string
  interaction_type?: 'input' | 'select' | 'files'
  interaction_result?: string
  optOut?: {
    gtag?: boolean
    posthog?: boolean
  }
}

export function trackEventV2(params: EventsV2) {
  const { name, optOut, ...eventParams } = params

  // Send to GA
  if (window?.gtag && !optOut?.gtag) window.gtag('event', name, eventParams)

  // Send to posthog
  if (!optOut?.posthog) posthog.capture(name, eventParams)
}
